import React from 'react';
import { useQuery, gql } from '@apollo/client';

import HandleLoading from '@hoc/handle-loading/handle-loading';
import MainLayout from '@hoc/layout';
import ContactSection from '@components/contact-section/contact-section';
import MapSection from '@components/map-section/map-section';
import BreadCrumbs from '@components/bread-crumbs/bread-crumbs';
import Seo from '@components/seo/seo';

import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';
import './wp-styles.scss';


const PAGE_QUERY = gql`
    query BlogPostsQuery($slug: String) {
        offerBy(slug: $slug) {
            content
        }
    }
`;


const PrivacyPolicy = () => {

  const { loading, error, data } = useQuery(PAGE_QUERY, {
    variables: {slug: 'leasing-sprzetu-it'}
});

  return (
    <MainLayout>

      <BreadCrumbs />

      <Seo
        title='Leasing sprzętu IT'
				description='Leasing i wynajem długoterminowy samochodów, maszyn i urządzeń, sprzętów budowlanych. ClickLease to najszybciej rozwijający się broker leasingu na polskim rynku. W swojej ofercie mamy aż 40 firm leasingowych. To szeroki wachlarz możliwości.'
      />

      <section className={`${s_wrapper.apply} ${s_wrapper.apply_height}`}>

        <article className={s_text.text_view}>
          <HandleLoading loading={loading} error={error}>

            <h1>Leasing sprzętu IT</h1>

            <div dangerouslySetInnerHTML={{ __html: data?.offerBy.content }}>
            </div>

          </HandleLoading>

          {/* <div className={s_text.double}>
            <div>
              <h2>Sprzęt elektroniczny, diagnostyczny, IT itp.</h2>
              <p>
                Działasz w branży IT, masz biuro lub małą firmę.
              </p>
              <p>
                Zapewnię zdajesz sobie sprawę, jak ważne jest korzystanie z nowoczesnego sprzętu, laptopa,
                drukarki, plotera, sprzętu diagnostycznego, telefonu komórkowego czy innego sprzętu elektronicznego.
              </p>
            </div>

            <div>
              <p>
                Dodatkowo finansujemy również oprogramowanie, monitory, sprzęt audiowizualny
                (rzutniki, ekrany, projektory, nagłośnienie, sprzęt HI-FI, RTV i AGD, monitoring, kamery itp)
              </p>
              <p>
                Posiadamy w ofercie leasing na sprzęt elektroniczny od 2 do 500 tys zł. Poznaj ofertę i błyskawiczną decyzję.
              </p>
              <p>
                Nie zwlekaj napędź swój biznes już teraz i wymień sprzęt na nowy!.
              </p>
            </div>
          </div> */}

        </article>

      </section>

      <ContactSection />
      <MapSection />

    </MainLayout>
  );

}

export default PrivacyPolicy;
